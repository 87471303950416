import { createGlobalStyle } from 'styled-components/macro'

export default createGlobalStyle`
/* Montserrat 300 latin*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: ;
  src: local('Montserrat Light'), local('Montserrat-Light'), url("https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Montserrat 500 latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url("https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Montserrat 700 lating */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url("https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    /* forest-green */
    --primary-dark:#014421; 
    /* gold */
    --primary-light:#e6ac00;
    /* dark gray for Text */
    --primary-darkgray:#333333;
    /* transparent dark gray for Text background on pictures*/
    --secondary-darkgray: rgba(51,51,51,0.75);
    /* off white text for dark background */
    --secondary-light: #f8f8f8;

    --primary-light-opacity: rgba(230,172,0, 0.50)
}

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
} 

a {
    color: var(--primary-dark);
    
    &:hover, &:focus {
    color: var(--primary-light) ;
  }

  &:focus {
    outline: 0;
    border-radius: 4px;
    border-color: var(--primary-dark);
    box-shadow: 0 0 0 1px;
  }
}

html {
    font-size: 62.5%;
    height: 100vh;
    scroll-behavior: smooth;
}

body {
    color: var(--primary-darkgray);
    font-weight: lighter; 
    line-height: 1.5;
    height: 100vh;  
    margin: 0 auto;
}

main {
    box-sizing: border-box;
    font-size: 1.8rem;
    margin: 0 12px;
    padding: 12px 12px 24px;
    min-height: 100%;
}

h1, h2, h3 {
    color: var(--primary-dark);
    line-height: 1;
    margin-bottom: 1.2rem;
}

h1 {
    font-size: 3.4rem;
    text-transform: uppercase; 
}

h2 {
    font-size: 2.8rem;
}

h3 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
    padding-top: 1.6rem;
}

img {
    /* aspect-ratio: attr(width) / attr(height); */
    background: inherit;
}

input {
    line-height: 1.5;
    height: 4.8rem;
}

p {
    font-size: 1.6rem; 
    margin-bottom: 1rem;
}

section {
    margin-bottom: 40px;
}

ul {
    margin: 10px 0 0 ; 
}

li {
    font-size: 1.8rem;
    line-height: 1.5;
}

.highlight {
    box-shadow:  inset 0 -8px 0 var(--primary-light);
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.error-message {
    color: red;
    font-style: italic;
} 

.input__style {
    border: solid 1px var(--primary-dark);
    border-radius: 8px;
    color: var(--primary-dark);
    font-size: 1.6rem;
    font-weight: 300;
    padding: 12px;
    width: 95%;
        
    :focus {
        border: solid 2px var(--primary-light);
        outline: none;
    }

    :required:invalid {
        box-shadow: none;
    }
    
    ::placeholder {
        color: var(--primary-light);
    }
}
`
